import React from "react"
import "./salientFeatures.scss"
import {
  dataSalientFeatures,
  dataSalientFeaturesItem
} from "../../../../data/Industries/dataSalientFeatures"
import svg1 from "../../../../assets/img/media/industries/construction/svg/Icon-1.svg"
import svg2 from "../../../../assets/img/media/industries/construction/svg/Icon-lock.svg"
import svg3 from "../../../../assets/img/media/industries/construction/svg/Icon-setting.svg"
import { withTranslation } from 'react-i18next'

const SalientFeatures = props => {
  const { t } = props
  return (
    <>
      <div className="salientFeatures">
        {/* feature */}
        <div className="feature">
          <div className="contenOfSalientFeatures">
            <div className="bgFeature">
            </div>
            <div className="content">
              <div className="titleSalientFeatures">
                {t('src.pages.construction.dataSalientFeatures.title')}
              </div>
              <div className="contentSalientFeatures">
              {t('src.pages.construction.dataSalientFeatures.content')}
              </div>
            </div>
          </div>

          <div className="featureItem">
            <div className="advantage singleItem">
              <div className='contentItem-title title0'>
              {t('src.pages.construction.dataSalientFeaturesItem1.title')}
              </div>
              <div className="contentItem item0">
                {t('src.pages.construction.dataSalientFeaturesItem1.content').map((data, index) => (
                  <div className="data-content data0" key={index}>{data}</div>
                ))}
                <img src={svg1} className="iconSvg" alt="svg" />
              </div>
            </div>
            <div className="support singleItem">
              <div className='contentItem-title title1'>
              {t('src.pages.construction.dataSalientFeaturesItem2.title')}
              </div>
              <div className="contentItem item1">
                <div className="item-icon">
                  <img src={svg2} className="iconSvg" alt="svg" />
                </div>
                {t('src.pages.construction.dataSalientFeaturesItem2.content').map((data, index) => (
                  <div className="data-content data1" key={index}>{data}</div>
                ))}
              </div>
            </div>
            <div className="security singleItem">
              <div className='contentItem-title title2'>
              {t('src.pages.construction.dataSalientFeaturesItem3.title')}
              </div>
              <div className="contentItem item2">
                {t('src.pages.construction.dataSalientFeaturesItem3.content').map((data, index) => (
                  <div className="data-content data2" key={index}>{data}</div>
                ))}
                <div className="item-icon">
                  <img src={svg3} className="iconSvg" alt="svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default withTranslation()(SalientFeatures)
