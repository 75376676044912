import React from "react"
import ConstructionPageTitle from "./constructionPageTitle/constructionPageTitle"
import Introduce from "./introduce/introduce"
import Feature from "./feature/feature"
import Layout from "../../layout/layout.higherComponent"
import Head from "../../head"
import SalientFeatures from './salientFeatures/salientFeatures'

const ConstructionPage = () => {
  return (
    <Layout displayHeader="true">
      <Head title="Pharma" />
      <ConstructionPageTitle />
      <Introduce />
      <Feature />
      <SalientFeatures />
    </Layout>
  )
}

export default ConstructionPage
