import React from "react"
import Group5 from '../../../../assets/img/media/industries/construction/Group_5.png'
import Group4 from '../../../../assets/img/media/industries/construction/Group_4.png'
import Group3 from '../../../../assets/img/media/industries/construction/Group_3.png'
import Group49 from '../../../../assets/img/media/industries/construction/Group_49.svg'
import Group48 from '../../../../assets/img/media/industries/construction/Group_48.svg'
import Group50 from '../../../../assets/img/media/industries/construction/Group_50.svg'
import { withTranslation } from 'react-i18next'
import './feature.scss'

const Feature = (props) => {
  const { t } = props
  return (
    <section className="bg-feature">
      <div className="feature-content">
        <div className="feature-title">
        {t('src.pages.construction.feature')}
        </div>
        <div className="features">
          <div className="feature">
            <div style={{ textAlign: 'center' }}>
              <img className="lagreScreen" src={Group3} alt="icon" />
              <img className="MoblieScreen" src= {Group48} alt="icon" />
            </div>
            <div className="content-feature">
              {t('src.pages.construction.DataFeature1').map((val, idx) => (
                <li key={idx}>
                  {val}
                </li>
              ))}
            </div>
          </div>
          <div className="feature">
            <div style={{ textAlign: 'center' }}>
              <img className="lagreScreen" src={Group4} alt="icon" />
              <img className="MoblieScreen" src= {Group49} alt="icon" />
            </div>
            <div className="content-feature">
            {t('src.pages.construction.DataFeature2').map((val, idx) => (
                <li key={idx}>
                  {val}
                </li>
              ))}
            </div>
          </div>
          <div className="feature">
            <div style={{ textAlign: 'center' }}>
              <img className="lagreScreen" src={Group5} alt="icon" />
              <img className="MoblieScreen" src= {Group50} alt="icon" />
            </div>
            <div className="content-feature">
            {t('src.pages.construction.DataFeature3').map((val, idx) => (
                <li key={idx}>
                  {val}
                </li>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Feature)
