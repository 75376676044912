import React from "react"
import ConstructionPage from "../components/industries/construction/construction";

const ConstructionIndustries = () => {
  return (
    <ConstructionPage/>
  )
}

export default ConstructionIndustries
