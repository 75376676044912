import React from "react"
import { withTranslation } from "react-i18next"
import "./introduce.scss"

const Introduce = props => {
  const { t } = props
  return (
    <section className="bg-introduce">
      <div className="content-introduce">
        <p className="introduce">{t("src.pages.construction.introduce")} </p>
        <div className="main-content">
          <div className="rectange"></div>
          <p>{t("src.pages.construction.contentintroduce1")}</p>
          <p>{t("src.pages.construction.contentintroduce2")}</p>
          <p>{t("src.pages.construction.contentintroduce3")}</p>
          <p className="sologan">{t("src.pages.construction.sologan")}</p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Introduce)
